<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col sm="12" md="10" lg="10">
        <v-card flat>
          <v-toolbar flat></v-toolbar>
        </v-card>
        <v-card outlined tile class="mb-5">
          <v-list-item>
            <v-list-item-title class="text-h6 font-weight-bold text-center">
              이용약관 및 개인정보처리 동의
            </v-list-item-title>
          </v-list-item>
        </v-card>
        <v-card outlined tile height="300" class="mb-10" style="overflow-y: auto">
          <terms></terms>
        </v-card>
        <v-card flat class="mb-3">
          <div class="font-weight-bold text-center">
              서비스를 이용하기 위해서 약관에 동의하셔야 합니다.
          </div>
        </v-card>
        <v-card flat class="mb-5">
          <v-list-item>
            <v-list-item-title class="text-h6 text-center">
              <v-btn
                text
                class="mr-3"
                @click="cancel"
              >약관에 동의하지 않음</v-btn>
              <v-btn
                outlined tile
                color="primary"
                @click="agree"
              >약관에 동의함</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>
<script>
import Terms from '../public/TermsCode'
export default {
  components: { Terms },
  data () {
    return {
      //
    }
  },
  methods: {
    async agree () {
      const user = this.$firebase.auth().currentUser

      const r = await this.$firebase.firestore().collection('users').doc(user.uid).get()
      if (r.exists) {
        await this.$firebase.firestore().collection('users').doc(user.uid).update({
          acceptTerms: 'Y'
        })
      }

      this.$router.push('/manage/academy')
    },
    cancel () {
      this.$firebase.auth().signOut()
      this.$router.push('/auth/signin')
    }
  }
}
</script>
